@import "src/assets/styles/variables.scss";
@import "src/assets/styles/global-style.scss";

.text-hover {
  transition: $trn-dur-1 color;
}

.text-hover:hover {
  color: $title-clr;
}

.form-inline-controls {
  @include flex-between;

  width: 100%;
}

.fs-control {
  background-color: $main-bg-clr;
  border-radius: 4px;
  transition: 300ms all;
  border: 1px solid $form-field-brd-color;
}

.disabled-filter {
  filter: brightness(0.6) grayscale(1) !important;
}

.readonly-control {
  cursor: default !important;
  pointer-events: none !important;
}

.fs-disabled {
  @extend .disabled-filter, .readonly-control;
}

.fs-el-deactive {
  filter: brightness(0.8) grayscale(1) !important;
}

.fs-input-field-readonly {
  background-color: transparent !important;
  border: none !important;
}

.x-rotate-0 {
  transform: rotateX(0deg);
}

.x-rotate-180 {
  transform: rotateX(180deg);
}

.clear-btn {
  min-width: 0px !important;
  padding: 0 !important;
}

//------------Button styles--------------
button.card-action-button {
  @include bg-contain;

  width: 200px !important;
  height: 50px !important;
  font-size: 1.25rem;
  text-transform: uppercase;
  background-image: url("#{$svg-path}/Rectangle 4290.svg") !important;
  cursor: pointer;
}
//--------------------------------------

//-----------a HTML Tag styles-----------
.link {
  font-size: 1rem;
  font-weight: 400;
  color: $link-clr;
}

.mask-link {
  @include float-layer;
  @include full-size;
}
//------------------------------------

//----------Browser scroll styles-------
::-webkit-scrollbar {
  width: 0.8rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: $gray-3-clr;
  width: 8px;
}
//--------------------------------------

h3.title {
  color: $title-clr;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
}

//------------------FS Cards-------------------
.fs-card {
  .fs-card-footer {
    @include flex-center;

    width: 100%;
    position: absolute;
    bottom: 40px;
  }

  .fs-card-quick-link-container {
    @include flex-evenly;
    @include flex-wrap;

    .fs-card-quick-link {
      margin-bottom: 22px;
    }
  }
}

fs-simple-card {
  fs-simple-card-body {
    .venue-card-label {
      @include float-layer;
      @include flex-start;

      padding: 11px 10px;
      font-family: inherit;

      span {
        font-family: inherit;
        font-weight: 700;
        font-size: 1rem;
        color: $title-clr;
        text-transform: uppercase;
      }
    }

    .veneu-events {
      @include flex-center;
      @include flex-col;

      position: absolute;
      bottom: 6px;
      right: 12px;
      z-index: 1;

      span:nth-child(1) {
        color: $primary-clr;
        font-size: 1.5rem;
        font-weight: 700;
        height: 26px;
        font-family: "Stratos-Bold";
      }

      span:nth-child(2) {
        font-size: 0.875rem;
        color: $gray-4-clr;
      }
    }
  }
}

.fav-container {
  @include flex-center;

  width: 100%;

  .fav {
    @include bg-view;

    width: 76px;
    height: 25px;
    background-image: url("#{$svg-path}/FAV-Del.svg");
  }
}
//--------------------------------------

//-------------Venue info-------------
:host ::ng-deep {
  .venue-summ {
    @include flex-between;

    align-items: flex-start;
    width: 100%;
    position: relative;

    .venue-profile {
      @include flex-start;

      width: 40%;
      align-items: stretch;

      img {
        width: 124px;
        height: 124px;
        margin-bottom: 10px;
      }

      .venue-name {
        @include flex-start;
        @include flex-col;

        margin-inline-start: 15px;
        align-items: flex-start;

        .venue-title {
          @include text-overflow;
        }

        .venue-title,
        fs-list-selector h4 {
          @extend .text-overflow;

          color: $title-clr !important;
          font-size: 1.25rem !important;
          font-weight: 700;
          max-width: 20rem;
        }

        .venue-title,
        .description {
          margin-bottom: 4px;
        }

        h3 {
          @extend .text-overflow;

          font-weight: 700;
          font-size: 1.5rem;
          max-width: 26rem;
          color: $white-clr;
          text-transform: uppercase;
        }

        h6 {
          font-weight: 400;
          font-size: 1.5rem;
          width: inherit;
          color: $title-clr;
        }

        .description {
          font-size: 1.25rem;
          font-weight: 400;
          line-height: 1.6rem;
          width: inherit;
        }
      }
    }

    .event-info {
      @include flex-end;
      @include flex-col;

      width: 50%;
      align-items: flex-end;
      text-transform: uppercase;

      .popup-info {
        margin-inline-start: 8px;
        margin-bottom: -4px;
      }

      h2 {
        font-weight: 700;
        font-size: 2rem;
        color: $white-clr;
        max-width: 100%;

        a {
          @extend .text-overflow;
        }
      }

      p {
        color: $white-clr;
        font-size: 1.5rem;
      }

      & > .status {
        span {
          font-size: 1.625rem;
          text-transform: uppercase;
        }

        & > .completed {
          color: $danger-clr;
        }

        & > .inprogress {
          color: $green-1-clr;
        }
      }

      // & > .actions {
      //   & > button {
      //     @extend .uppercase;

      //     @include bg-auto;
      //     @include flex-center;

      //     height: 34px !important;
      //     width: 100%;
      //     font-size: 1.25rem;
      //     margin-bottom: 12px;
      //   }

      //   // .scorecard {
      //   //   @extend .bg-rect-blue;
      //   // }

      //   // .eventinfo {
      //   //   @extend .bg-rect-yellow;
      //   // }

      //   // .participants {
      //   //   @extend .bg-rect-black;
      //   // }
      // }
    }

    .venue-details {
      max-width: 270px;
      text-align: end;

      & > * {
        overflow-wrap: break-word;
      }

      h3 {
        color: $title-clr;
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 4px;
      }

      .description {
        font-size: 1.25rem;
        line-height: 1.6rem;
      }
    }
  }

  .actions-container {
    @include flex-between;

    align-items: stretch;

    .event-rounds {
      margin-bottom: 10px;

      & > h3 {
        text-align: center;
        font-weight: 700;
        font-size: 2rem;
        text-transform: uppercase;
        line-height: 39.36px;
        margin-bottom: 16px;
      }
    }

    & > div:first-child {
      width: 144px;
      margin-inline-end: 16px;
    }

    & > .actions {
      margin-inline-start: 16px;
      margin-top: 12px;

      & > .wrapper {
        width: 160px;

        & > button {
          @extend .uppercase;

          @include bg-auto;
          @include flex-center;

          height: 34px !important;
          width: 100%;
          font-size: 1.25rem;
          margin-bottom: 12px;
        }
      }
    }
  }
}
//----------------------------------------

//-----------Active scroll to element----
.fs-visited-el {
  backdrop-filter: contrast(0.8);
}
//------------------------------------

.event-rounds {
  @include flex-col;
  @include flex-center;

  width: 100%;

  .event-rounds-selector {
    fs-selector-field {
      .fs-selector-field {
        .fs-controls-overflow {
          & > button:first-child {
            span {
              color: $danger-clr !important;
              font-size: 1.375rem !important;
            }
          }
        }
      }
    }
  }

  .net-gross-switcher {
    @include flex-end;

    width: 100%;
  }
}

.scoring-switch {
  .fs-controls-gp-wrapper {
    border-radius: 100px;
    background-color: black;
    border: 1px solid $gray-14-clr;

    .label {
      span {
        font-size: 0.875rem;
      }
    }
  }

  .active-control {
    border-radius: 100px !important;
  }
}

.frame-search-field {
  .fs-field-wrapper {
    padding-block: 4px !important;
    padding-inline-start: 38px !important;
    padding-inline-end: 8px !important;

    input {
      font-size: 1.125rem !important;
      transform: none !important;
    }
  }
}

.leaderboard-table-strock {
  fs-table {
    th:nth-child(1),
    th:nth-child(2),
    th:nth-child(4),
    th:nth-child(5),
    th:nth-child(6),
    th:nth-child(7),
    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7) {
      & > * {
        @include flex-center;
      }
    }
  }
}

.leaderboard-table-wl {
  fs-table {
    th:nth-child(1),
    th:nth-child(2),
    th:nth-child(4),
    th:nth-child(5),
    th:nth-child(6),
    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6) {
      & > * {
        @include flex-center;
      }
    }
  }
}

.fs-thead-isolated {
  @include flex-start;

  margin-bottom: 6px;
  align-items: stretch;
  background-color: #575961;
  height: 34px;
  border-bottom: none;

  & > div {
    @include flex-center;

    text-transform: unset;
    border: 1px solid #797979;
    padding-inline: 12px;

    div {
      @include flex-center;

      p {
        color: $gray-4-clr;
        font-weight: 400;
        font-size: 1.125rem;
        text-align: center;

        & > * {
          color: inherit;
        }
      }
    }
  }
}

.fs-table-ui-2 {
  width: 100%;

  table {
    border-collapse: separate !important;

    thead {
      tr {
        background-color: $gray-2-clr;
        height: 34px !important;
        border-bottom: none !important;

        th {
          text-transform: unset !important;
          border: 1px solid #797979;
          padding-inline: 12px;

          p {
            color: $gray-4-clr;
            font-weight: 400;
            font-size: 1.125rem;

            & > * {
              color: inherit;
            }
          }
        }
      }
    }

    tbody {
      tr {
        background-color: $gray-10-clr;
        height: 36px;

        td {
          border: 1px solid $gray-9-clr;
          padding-inline: 12px;

          p {
            font-size: 1.125rem;
            color: $gray-5-clr;
            //padding-inline: 12px;

            & > * {
              color: inherit;
            }
          }
        }
      }
    }
  }
}

.fs-table-ui-3 {
  @extend .fs-table-ui-2;

  .header-utils {
    background-color: $gray-9-clr;
    height: 40px;
    width: 100%;
  }
}

//------Players table in my events tab------
.fs-table-ui-4 {
  //width: 800px;

  tbody {
    tr {
      background-color: $white-clr;
      border-bottom: 1px solid rgba(216, 218, 227, 1) !important;
      height: 56px !important;

      td {
        p {
          color: $main-bg-clr;
          font-weight: 700;
        }

        &:nth-child(2) {
          p {
            font-weight: 400;
          }
        }
      }
    }
  }
}
//-------------------------

.row-edit-ico {
  @extend .i-edit;
  @include bg-contain;

  width: 18px;
  height: 18px;
  display: inline-block;
  filter: brightness(0.8);
  margin-inline-end: 4px;
}

.events-list-table,
.my-events-table,
.scorecard-event-table {
  fs-table {
    tbody {
      tr {
        height: 60px;
      }
    }

    th {
      padding-block: 4px;

      p {
        color: $gray-7-clr;
      }
    }
  }
}

.events-list-table {
  display: block;

  fs-table {
    th:nth-child(2),
    th:nth-child(3),
    th:nth-child(4),
    th:nth-child(5),
    th:nth-child(6),
    th:nth-child(7),
    th:nth-child(8),
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7),
    td:nth-child(8) {
      & > * {
        @include flex-center;
      }
    }

    th:nth-child(1) {
      width: 340px;
    }

    th:nth-child(2) {
      width: 60px;
    }

    th:nth-child(3) {
      width: 110px;
    }

    th:nth-child(4) {
      width: 110px;
    }

    th:nth-child(5) {
      width: 100px;
    }

    th:nth-child(6) {
      width: 80px;
    }

    th:nth-child(7) {
      width: 150px;
    }

    th:nth-child(8) {
      width: 180px;
    }
  }
}

.action-button-details {
  button:not(.custom) {
    width: 125px;
    height: 36px;
  }
}

.scorecard-event-table {
  .fs-table-wrapper {
    width: 100% !important;
  }

  display: block;

  fs-table {
    tbody {
      tr {
        height: 60px;
      }
    }

    th:nth-child(2),
    th:nth-child(3),
    th:nth-child(4),
    th:nth-child(5),
    th:nth-child(6),
    th:nth-child(7),
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7) {
      & > * {
        @include flex-center;
      }
    }
  }
}

.preferences-tab {
  .fs-controls-gp-wrapper {
    border: 1px solid $button-brd-clr;
    background-color: $main-bg-clr;
    border-radius: 4px;

    .fs-controls-overflow {
      height: 36px !important;

      button {
        span {
          font-family: "DavisSans-Bold";
        }
      }
    }
  }
}

.infinite-icon-34 {
  @include bg-view;

  width: 44px;
  height: 20px;
  filter: contrast(0.1);
  background-image: url("#{$image-path}/Infinite.png");
}

.captain-mark {
  @include flex-center;

  font-family: "Stratos-Bold";
  font-size: 1.375rem !important;
  font-weight: 700;
  border: 1px solid $button-brd-clr;
  border-radius: 2px;
  width: 24px;
  height: 24px;
  margin-inline-end: 8px;
}

.fs-default-input {
  background-color: transparent;
  border: none;
  box-sizing: unset;
  outline: none;
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  font-weight: inherit;
  width: inherit;
  text-align: center;
  height: 100%;
}

.fs-outline-circular {
  border-radius: 100%;
}

.fs-outline {
  padding: 4px;
  border: 2px solid;
  border-color: inherit;
}

.fs-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: transparent;
  top: 0;
  left: 0;
  z-index: 99;

  &.backdrop {
    -moz-backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    -o-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: #000000a1;
    opacity: 0;
    transition: opacity 150ms;
  }

  .child-wrapper {
    @include w-fit-content;
    @include h-fit-content;

    position: relative;
  }

  .layout {
    background-color: black;
    border-radius: 4px;
    overflow: hidden;

    .fs-selector-field {
      margin-inline: 0;

      .fs-controls-gp-wrapper {
        width: 100%;

        .control-item {
          .label {
            border: 1px solid;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

//موقت
// #fs-overlay {
//   z-index: 100 !important;
// }

.form-valid,
.form-error {
  @include bg-view;

  width: 20px;
  height: 20px;

  span {
    font-size: 0.813rem;
  }
}

.form-valid {
  background-image: url("#{$icon-path}/checked.svg");
}

.form-error {
  background-image: url("#{$icon-path}/form-error-20.svg");
}

.close {
  @include bg-view;

  background-image: url("#{$svg-path}/close.svg");
  position: absolute;
  right: 15px;
  top: 27px;
  z-index: 1;
  width: 20px;
  height: 20px;
}

.fs-group-button-round {
  .fs-selector-field {
    border: 1px solid rgba(133, 133, 133, 1);
    border-radius: 20px;

    .fs-controls-overflow {
      height: 34px !important;
      border-radius: 20px !important;

      .active-button {
        border-radius: 20px !important;
      }

      button:last-child {
        border-start-end-radius: 20px !important;
        border-end-end-radius: 20px !important;
      }

      button:first-child {
        border-start-start-radius: 20px !important;
        border-end-start-radius: 20px !important;
      }

      button {
        height: 100% !important;
      }
    }
  }
}

.my-events-table {
  fs-table {
    th:nth-child(1),
    th:nth-child(2),
    th:nth-child(4),
    th:nth-child(5),
    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(4),
    td:nth-child(5) {
      & > * {
        @include flex-center;
      }
    }

    td:nth-child(6) {
      & > * {
        @include flex-end;
      }
    }

    td:nth-child(1) {
      width: 70px;
    }

    th:nth-child(2) {
      width: 100px;
    }

    th:nth-child(3) {
      width: 400px;
    }

    th:nth-child(4) {
      width: 130px;
    }

    th:nth-child(5) {
      width: 85px;
    }

    th:nth-child(6) {
      width: 250px;
    }
  }
}

.my-teams-table {
  fs-table {
    // th:nth-child(1),
    // th:nth-child(3),
    // th:nth-child(4),
    // th:nth-child(5),
    // th:nth-child(6),
    // th:nth-child(7),
    // td:nth-child(1),
    // td:nth-child(3),
    // td:nth-child(4),
    // td:nth-child(5),
    // td:nth-child(6),
    // td:nth-child(7),
    // td:nth-child(8) {
    //   & > * {
    //     @include flex-center;
    //   }
    // }

    td:nth-child(2) {
      & > * {
        @include flex-start;

        flex-wrap: wrap;
      }
    }

    td:nth-child(3) {
      text-align: center;
    }
  }
}

.fs-button-border {
  border: 1px solid $button-brd-clr;
}

.score-val {
  @include flex-center;

  width: 22px;
  height: 22px;
  color: inherit;
  //border-radius: 2px;
}

.fs-compete-logo {
  @include bg-view;

  width: 380px;
  height: 62px;
  min-height: 62px;
  margin-bottom: 14px;
  background-image: url("#{$svg-path}/FS Compete Logo.svg");
}

.venue-cards-wrapper {
  @include flex-center;
  @include flex-wrap;

  width: calc(100% - 25px);

  .venue-card {
    margin: 6px;
  }

  .venue-title {
    @include text-overflow;

    color: $title-clr;
    font-size: 1rem;
    max-width: 80%;
  }
}

.no-content {
  @include flex-center;

  width: 100%;
  height: 100%;
}

.fs-small-message {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.313rem;
  display: flex;

  &.error {
    color: $error-light;

    &::before {
      @extend .i-cir-error;
      @include bg-auto;

      margin-inline-end: 4px;
      content: "";
      display: block;
      width: 20px;
      height: 20px;
    }
  }

  &.success {
    color: white;

    &::after {
      @extend .i-checked;
      @include bg-auto;

      margin-inline-start: 4px;
      content: "";
      display: block;
      width: 20px;
      height: 20px;
    }
  }

  &.normal {
    color: $gray-clr;
  }
}

//استایل های آشغال . بعدا برم سراغش
.tick-24-oline-cir {
  background-image: url("#{$icon-path}/Tick-24-OutlineCircle.svg");
}

.add-24-oline-cir {
  background-image: url("#{$icon-path}/Add-24-OutlineCircle.svg");
}

.main-24 {
  background-image: url("#{$icon-path}/Mail-24.svg");
}

.clr-gray-4 {
  color: $gray-4-clr;
}

.clr-yellow {
  color: $title-clr;
}

.clr-danger {
  color: $danger-clr !important;
}

.clr-green {
  color: $green-1-clr !important;
}

.bg-yellow {
  background-color: $title-clr !important;
}

.bg-dark-gray {
  background-color: $dark-gray;
}

.bg-black {
  background-color: $main-bg-clr;
}

.my-events-tbl {
  tbody {
    tr {
      td:nth-child(1) {
        p {
          font-size: 22px;
        }
      }

      td:nth-child(2) {
        p {
          color: $gray-4-clr;
          font-size: 16px;
        }
      }

      td:nth-child(3) {
        p {
          font-size: 20px;
        }
      }

      td:nth-child(4) {
        p {
          font-size: 22px;
        }
      }

      td:nth-child(5) {
        p {
          font-size: 20px;
        }
      }
    }
  }
}

.add-fav-location-venue-card {
  fs-simple-card-footer {
    .fs-simple-card-footer {
      bottom: 140px;
    }
  }
}

.fs-league-table-utils {
  @include flex-end;

  width: calc(100% - 114px);
  margin-bottom: 4px;

  & > div {
    @include flex-center;

    span {
      font-size: 1rem;
    }

    .info {
      @include bg-view;

      width: 24px;
      height: 24px;
      background-image: url("#{$icon-path}/info-cir-transparent-24-yellow.svg");
    }
  }

  & > div:last-child {
    & > * {
      margin-inline-start: 8px;
    }
  }
}

// .completed {
//   background-image: url("#{$svg-path}/FS-Btn-BG-144-34-Red.svg");
// }

// .inprogress {
//   background-image: url("#{$svg-path}/FS-Btn-BG-144-34-Green.svg");
// }

.event-scorecard-table {
  tbody {
    tr {
      td:first-child {
        p {
          span {
            padding-inline-start: 8px;
            color: $gray-4-clr;
          }
        }
      }
    }
  }
}

.regitered-teams-tables {
  .fs-league-table {
    .league-info {
      .team-name {
        h4 {
          color: $green-1-clr !important;
          font-size: 18px;

          a {
            display: block;
            color: inherit;
          }
        }
      }
    }
  }
}

.logout-24-action {
  @include bg-view;

  background-image: url("#{$icon-path}/Logout-24.svg");
  display: block;
  width: 24px;
  height: 24px;
}

.tee-bk {
  background-image: url("#{$icon-path}/Tee-BK.svg");
}

.tee-bl {
  background-image: url("#{$icon-path}/Tee-BL.svg");
}

.tee-ch {
  background-image: url("#{$icon-path}/Tee-CH.svg");
}

.tee-gr {
  background-image: url("#{$icon-path}/Tee-GR.svg");
}

.tee-rd {
  background-image: url("#{$icon-path}/Tee-RD.svg");
}

.tee-wh {
  background-image: url("#{$icon-path}/Tee-WH.svg");
}

.handicap-field {
  width: 88px !important;
  height: 62px !important;
  margin-bottom: 2px !important;

  .fs-form-field {
    width: inherit !important;
    height: inherit !important;

    .fs-field-wrapper {
      padding-inline-start: 20px;

      label {
        top: 7px;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 0.875rem;
        font-weight: 400;
      }

      input[type="number"] {
        font-family: "Stratos-Bold";
        font-size: 1.75rem !important;
        text-align: center !important;
        margin-top: 1px !important;
        padding-inline-start: 2px !important;
      }
    }
  }
}

//********************************************************************
//------------Backdrop modal--------------
.background-blur,
.cdk-overlay-dark-backdrop {
  -moz-backdrop-filter: blur(6px) !important;
  -webkit-backdrop-filter: blur(6px) !important;
  -o-backdrop-filter: blur(6px) !important;
  backdrop-filter: blur(6px) !important;
  background-color: #000000a1 !important;
}
//------------------------------------

//-------------Global table cell---------
.cell-rect {
  @include flex-center;

  width: 36px;
  height: 24px;
  border-radius: 3px;
  color: $gray-8-clr;
}

.cell-bg-yellow {
  background-color: $title-clr;
}

.cell-bg-white {
  background-color: $white-clr;
}

.cell-date-clr {
  color: $gray-4-clr;
}

.cell-act-in-progress {
  background-color: $green-1-clr;
  color: $white-clr;
}

.cell-act-reg-bl {
  background-color: $primary-clr;
  color: $white-clr;
}

.cell-act-reg {
  background-color: $title-clr;
  color: $main-bg-clr;
}

.cell-act-res {
  background-color: $danger-clr;
  color: $white-clr;
}

.leave-action {
  @include bg-view;

  width: 32px !important;
  height: 32px !important;
  background-image: url("#{$icon-path}/Logout-32.svg");
  margin-inline-end: 10px;
}
//-------------------------------------------

//--------Sim settings tab options list-------
#tab-sim-settings-container {
  .fs-list-container {
    .fs-list-item {
      height: 48px;
      padding-inline-end: 14px;
    }
  }

  & > div:first-child {
    .fs-controls-overflow {
      height: 46px;

      button {
        font-size: 1.75rem;
        border: $button-brd-clr 1px solid;
        width: 100px !important;
      }
    }
  }
}
//---------------------------------

//----------Venue page events table-------------
.venue-events-table {
  tr {
    & > td:first-child p {
      font-size: 1.25rem;
    }

    & > td:nth-child(6) p {
      font-size: 1.5rem;
    }
  }
}
//-----------------------------------

//------Players table in my events tab------
.payer-table {
  width: 800px;

  thead {
    tr {
      & > th:nth-child(3) {
        padding-inline-start: 20px;
      }
    }
  }
  tbody {
    tr {
      background-color: $white-clr;
      border-bottom: 1px solid rgba(216, 218, 227, 1) !important;

      td {
        p {
          color: $main-bg-clr;
          font-weight: 700;
        }

        &:nth-child(2) {
          p {
            font-weight: 400;
          }
        }

        // &:nth-child(4) {
        //   p {
        //     color: $danger-clr;
        //   }
        // }
      }

      & > td:nth-child(3) {
        padding-inline-start: 20px;
      }
    }
  }
}
//-------------------------

//-------Registered table---------
.tbl-registered-container {
  width: 573px;

  & > div:first-child {
    .tbl-leadbrd-header {
      .team-info {
        .team-name {
          color: $green-1-clr !important;
        }
      }
    }
  }

  .tbl-leadbrd-header {
    @include flex-center;

    height: 40px;
    width: 100%;
    background-color: $gray-9-clr;
  }

  .tbl-leadbrd-body {
    tbody {
      tr {
        background-color: $gray-10-clr;

        td {
          padding-block: 6px !important;
          border: 1px solid $gray-9-clr;

          &:last-child {
            button[name="Add"] {
              @include bg-view;

              width: 18px;
              height: 18px;
              background-image: url("#{$icon-path}/ADD-24.svg");
            }
          }

          p {
            padding-inline: 8px;
            color: $gray-5-clr;
            font-size: 1.125rem;
          }
        }
      }
    }
  }
}
//--------------------------------
//-----------Round table details-----------------
#round-details {
  .content {
    tbody {
      tr {
        td {
          padding-block: 8px !important;
        }
        & > td:first-child {
          color: $gray-4-clr !important;
        }
      }
    }
  }
}

.fs-stack-list-item-bold {
  .cells-wrapper {
    font-weight: 700 !important;
  }
}

.fs-stack-list-item-deactive {
  .cells-wrapper {
    color: $gray-8-clr !important;
  }
}

.fs-stack-list-cell-2rc {
  @include bg-view;

  background-image: url("#{$svg-path}/Red-2-Circle.svg");
}

.fs-stack-list-cell-rc {
  @include bg-view;

  background-image: url("#{$svg-path}/Red-Circle.svg");
}

.fs-stack-list-cell-2bs {
  @include bg-view;

  background-image: url("#{$svg-path}/Blue-2-Square.svg");
}

.fs-stack-list-cell-bs {
  @include bg-view;

  background-image: url("#{$svg-path}/Blue-Square.svg");
}
//----------------------------------------------
//----------Group button team playe selector-------
.players-per-team-gp-buttons {
  .fs-controls-gp-wrapper {
    width: 100% !important;
    overflow: hidden !important;

    .fs-controls-overflow {
      @include flex-start;

      width: inherit !important;
      height: 30px !important;

      button {
        background-color: $gray-7-clr !important;
        margin-inline: 15px !important;
        position: unset !important;
        width: 44px !important;

        span {
          font-weight: 700 !important;
          font-size: 1.5rem !important;
          color: $gray-11-clr !important;
          text-shadow: none !important;
        }
      }

      .active-button {
        background-color: $title-clr !important;
      }
    }
  }
}
//-------------------------------------------------

.fs-team-table-header {
  @include flex-between;

  position: relative;
  align-items: stretch;
  padding: 8px 14px;
  padding-inline-end: 8px;
  background-color: $gray-9-clr;
  overflow: hidden;

  .league-info {
    @include flex-start;

    width: 100%;

    .team-name {
      @extend .text-overflow;

      color: $gray-11-clr;
      text-transform: uppercase;
      font-size: 1.125rem;
      font-weight: 700;
      max-width: calc(100% - 200px);
    }

    & > * {
      padding-inline-start: 6px;
    }
  }

  .league-actions {
    @include flex-end;

    position: relative;
  }
}
//----------------------------------------

.fs-small-message {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 21px;
  display: flex;

  &.error {
    color: $error-light;

    &::before {
      @extend .i-cir-error;
      @include bg-auto;

      margin-inline-end: 4px;
      content: "";
      display: block;
      width: 20px;
      height: 20px;
    }
  }

  &.success {
    color: white;

    &::after {
      @extend .i-checked;
      @include bg-auto;

      margin-inline-start: 4px;
      content: "";
      display: block;
      width: 20px;
      height: 20px;
    }
  }

  &.normal {
    color: $gray-clr;
  }
}
