/***h => height***
****w => width****
****clr => color****
****bg => background***
****sub => subtract***/

//------------Break point screen--------------
$small-screen: 576px;
$medium-screen: calc(768px + 1px);
$large-screen: 991px;
$extra-large-screen: 1200px;
$x-extra-large-screen: 1400px;

//----------Elements size---------------
$frame-bg-h: 982px;
$frame-bg-w: 1227px;
$frame-header-h: 80px;

//---------Calculation variables---------
$screen-h-sub-frame-bg-h: calc(100vh - $frame-bg-h);
$screen-h-sub-frame-header-h: calc(100vh - $frame-header-h);
$frame-body-h: calc(100vh - 110px);

//----------Paths-------------
$image-path: "/assets/images";
$svg-path: "/assets/svgs";
$font-path: "/assets/fonts";
$icon-path: "/assets/icons";

$white-clr: #ffffff;
$white-2-clr: #f2f2f2;
$gray-clr: #bbbcbf;
$gray-2-clr: #575961;
$gray-3-clr: #a5a5a5;
$gray-4-clr: #bdbdbd;
$gray-5-clr: #6a6c74;
$gray-6-clr: #828282;
$gray-7-clr: #a1a4af;
$gray-8-clr: #6a6d77;
$gray-9-clr: #c9cacb;
$gray-10-clr: #e0e0e0;
$gray-11-clr: #3a3c41;
$gray-12-clr: #d8dae3;
$gray-13-clr: #323438;
$gray-14-clr: #858585;
$gray-15-clr: #dfe0e0;
$gray-16-clr: #333333;
$black-1-clr: #000000cc;
$black-2-clr: #000000b2;
$main-bg-clr: #000000;

$primary-clr: #3369ff;
$blue-medium: #3369ff;
$blue-dark: #214ecc;
$blue-light: #3399ff;
$link-clr: #98a6f9;
$blue-1-clr: #4bc3ff;

$success-clr: #1f883d;
$green-1-clr: #009840;
$green-2-clr: #7ed321;

$title-clr: #ffc108;
$warning-dark: #f2b824;

$danger-clr: #e51616;
$error-dark: #c73232;
$error-light: #f56e6e;

$dark-gray: #444444;

$button-brd-clr: #858585cc;
$button-bg-clr: #000000b2;
$modal-bg-clr: #000000b2;

$input-bg: #252526;
$input-color-txt: #ffffff;
$form-field-brd-color: #585858;
$input-color-label: #a8a9ac;
$input-hover-color: #838383;
$level3-gray: #29292a;

$grd-gray1-bg: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
  linear-gradient(0deg, rgba(133, 133, 133, 0.8), rgba(133, 133, 133, 0.8));

$trn-dur-1: 100ms;
$trn-dur-2: 200ms;
$trn-dur-3: 300ms;

//Class variables
/*
  Helps: 
    i => Icon
    bg => Background
    ol => Outline
    cr => Circle
*/

//Icons
.i-arrow-up {
  background-image: url("#{$icon-path}/Arrow-Up.svg");
}

.i-arrow-down {
  background-image: url("#{$icon-path}/Arrow-Down.svg");
}

.i-arrow-down-Black {
  background-image: url("#{$icon-path}/Arrow-Down-Black.svg");
}

.i-arrow-up-counter {
  background-image: url("#{$icon-path}/Arrow-Up-Counter.svg");
}

.i-arrow-down-counter {
  background-image: url("#{$icon-path}/Arrow-Down-Counter.svg");
}

.i-arrow-down-outline-cir {
  background-image: url("#{$icon-path}/Arrow-Down-Outline-Cir.svg");
}

.i-arrow-Left {
  background-image: url("#{$icon-path}/Arrow-Left.svg");
}

.i-arrow-right {
  background-image: url("#{$icon-path}/Arrow-Right.svg");
}

.i-user {
  background-image: url("#{$icon-path}/User-24.svg");
}

.i-person {
  background-image: url("#{$icon-path}/Persons-24.svg");
}

.i-tick-ol-cr {
  background-image: url("#{$icon-path}/Tick-24-OutlineCircle.svg");
}

.i-add-ol-cr {
  background-image: url("#{$icon-path}/Add-24-OutlineCircle.svg");
}

.i-mail {
  background-image: url("#{$icon-path}/Email-24.svg");
}

.i-phone {
  background-image: url("#{$icon-path}/Phone.svg");
}

.i-infinite {
  background-image: url("#{$icon-path}/Infinite.svg");
}

.i-logout {
  background-image: url("#{$icon-path}/Logout-24.svg");
}

.i-logout-32 {
  background-image: url("#{$icon-path}/Logout-32.svg");
}

.i-staff {
  background-image: url("#{$icon-path}/Staff-24.svg");
}

.i-super-user-blue {
  background-image: url("#{$icon-path}/Super-User-BL-28.svg");
}

.i-super-user-yellow {
  background-image: url("#{$icon-path}/Super-User-YL-28.svg");
}

.i-edit {
  background-image: url("#{$icon-path}/Edit-18.svg");
}

.i-edit-26 {
  background-image: url("#{$icon-path}/Edit-26.svg");
}

.i-search {
  background-image: url("#{$icon-path}/Search-24.svg");
}

.i-calendar {
  background-image: url("#{$icon-path}/Calendar.svg");
}

.i-trash-gray {
  background-image: url("#{$icon-path}/Trash-24-Gray.svg");
}

.i-lock-on-yellow {
  //background-image: url("#{$icon-path}/Lock-ON.svg");
  background-image: url("#{$icon-path}/Lock-ON-Yellow.svg");
}

.i-lock-off-gray {
  //background-image: url("#{$icon-path}/Lock-OFF.svg");
  background-image: url("#{$icon-path}/Lock-OFF-Gray.svg");
}

.i-info-24-yellow-cr {
  background-image: url("#{$icon-path}/info-cir-transparent-24-yellow.svg");
}

.i-db-36 {
  background-image: url("#{$icon-path}/Data-Folder-36.svg");
}

.i-search-36 {
  background-image: url("#{$icon-path}/Search-36.svg");
}

.i-settings-36 {
  background-image: url("#{$icon-path}/Settings-36.svg");
}

.i-company-36 {
  background-image: url("#{$icon-path}/Company-36.svg");
}

.i-trophy-36 {
  background-image: url("#{$icon-path}/Trophy-36.svg");
}

.i-tee-black {
  background-image: url("#{$icon-path}/Tee-BK.svg");
}

.i-tee-blue {
  background-image: url("#{$icon-path}/Tee-BL.svg");
}

.i-tee-chocolate {
  background-image: url("#{$icon-path}/Tee-CH.svg");
}

.i-tee-green {
  background-image: url("#{$icon-path}/Tee-GR.svg");
}

.i-tee-red {
  background-image: url("#{$icon-path}/Tee-RD.svg");
}

.i-tee-white {
  background-image: url("#{$icon-path}/Tee-WH.svg");
}

.i-tee-box {
  background-image: url("#{$icon-path}/Tee Box.svg");
}

.i-mulligan {
  background-image: url("#{$icon-path}/Mulligan.svg");
}

.i-gimmies {
  background-image: url("#{$icon-path}/Gimmie.svg");
}

.i-tracer-view {
  background-image: url("#{$icon-path}/Tracer.svg");
}

.i-player-view {
  background-image: url("#{$icon-path}/Eye.svg");
}

.i-full-shot-units {
  background-image: url("#{$icon-path}/Ruler.svg");
}

.i-putting-unit {
  background-image: url("#{$icon-path}/TMeasure.svg");
}

.i-sound-fx {
  background-image: url("#{$icon-path}/Sound.svg");
}

.i-pin-postion {
  background-image: url("#{$icon-path}/PinPos.svg");
}

.i-green-speed {
  background-image: url("#{$icon-path}/Speed.svg");
}

.i-green-firmness {
  background-image: url("#{$icon-path}/Green Firm.svg");
}

.i-putting-grid {
  background-image: url("#{$icon-path}/Grid.svg");
}

.i-putting-arrow {
  background-image: url("#{$icon-path}/PutArrow.svg");
}

.i-fairway {
  background-image: url("#{$icon-path}/Fairway.svg");
}

.i-wind {
  background-image: url("#{$icon-path}/Wind.svg");
}

.i-course-elevation {
  background-image: url("#{$icon-path}/Elevation.svg");
}

.i-captain-logo {
  background-image: url("#{$icon-path}/Captain-Logo.svg");
}

.i-cir-error {
  background-image: url("#{$icon-path}/circular-error-20.svg");
}

.i-checked {
  background-image: url("#{$icon-path}/checked-16.svg");
}
//----------------------------------------------------------

//Backgrounds
.bg-rect-red {
  background-image: url("#{$svg-path}/FS-Btn-BG-144-34-Red.svg");
  color: $white-clr;
}

.bg-rect-green {
  background-image: url("#{$svg-path}/FS-Btn-BG-144-34-Green.svg");
  color: $white-clr;
}

.bg-rect-blue {
  background-image: url("#{$svg-path}/FS-Btn-BG-144-34-Blue.svg");
  color: $white-clr;
}

.bg-rect-blue-112-36 {
  background-image: url("#{$svg-path}/FS-Btn-BG-112-36-Blue.svg");
  color: $white-clr;
}

.bg-rect-yellow {
  background-image: url("#{$svg-path}/FS-Btn-BG-144-34-Yellow.svg");
  color: $main-bg-clr;
}

.bg-rect-black {
  background-image: url("#{$svg-path}/FS-Btn-BG-144-34-Black.svg");
  color: $white-clr;
}

.bg-active-round {
  background-image: url("#{$svg-path}/Round-Active-BG.svg");
}

.bg-deactive-round {
  background-image: url("#{$svg-path}/Round-Deactive-BG.svg");
}

.main-background-1 {
  background-image: url("#{$image-path}/FSC-Bkgd-2 1.png");
}

.main-background-2 {
  background-image: url("#{$image-path}/Sawgrass17B 4.png");
}

.bg-rect-blue-40 {
  background-image: url("#{$svg-path}/Rectangle-40.svg");
}

.helper-question {
  background-image: url("#{$svg-path}/helper-question.svg");
}
//------------------------------------------------------------

//Background-color
.bg-primary-clr {
  background-color: $primary-clr !important;
  color: $white-clr !important;
}

.bg-success-clr {
  background-color: $green-1-clr !important;
  color: $white-clr !important;
}

.bg-green2-clr {
  background-color: $green-2-clr !important;
  color: $white-clr !important;
}

.bg-warning-clr {
  background-color: $title-clr !important;
  color: $main-bg-clr !important;
}

.bg-danger-clr {
  background-color: $danger-clr !important;
  color: $white-clr !important;
}

.bg-black-clr {
  background-color: $main-bg-clr !important;
  color: $white-clr !important;
}

.bg-dark-gray {
  background-color: $dark-gray !important;
  color: $white-clr !important;
}

.bg-dgray11-clr {
  background-color: $gray-11-clr !important;
  color: $white-clr !important;
}

.bg-lgray-clr {
  background-color: $gray-14-clr !important;
  color: $white-clr !important;
}

.bg-lgray7-clr {
  background-color: $gray-7-clr !important;
  color: $gray-16-clr !important;
}

.bg-blue1-clr {
  background-color: $blue-1-clr !important;
  color: $main-bg-clr !important;
}

//Foreground color
//Background-color
.primary-clr {
  color: $primary-clr !important;
}

.success-clr {
  color: $success-clr !important;
}

.warning-clr {
  color: $title-clr !important;
}

.danger-clr {
  color: $danger-clr !important;
}

.black-clr {
  color: $main-bg-clr !important;
}

.gray-4-clr {
  color: $gray-4-clr !important;
}

.white-clr {
  color: $white-clr !important;
}

//Outline colors
.ol-primary-clr {
  border-color: $primary-clr !important;
  color: $primary-clr !important;
}

.ol-success-clr {
  border-color: $green-1-clr !important;
  color: $green-1-clr !important;
}

.ol-green2-clr {
  border-color: $green-2-clr !important;
  color: $green-2-clr !important;
}

.ol-warning-clr {
  border-color: $title-clr !important;
  color: $title-clr !important;
}

.ol-danger-clr {
  border-color: $danger-clr !important;
  color: $danger-clr !important;
}

.ol-black-clr {
  border-color: $main-bg-clr !important;
  color: $main-bg-clr !important;
}

.ol-lgray-clr {
  border-color: $gray-14-clr !important;
  color: $gray-14-clr !important;
}

//Font size
.fx20 {
  font-size: 20px;
}
