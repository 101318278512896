@import "./assets/styles/variables.scss";
@import "./assets/styles/reset-styles.scss";
@import "./assets/styles/fonts.scss";
@import "./assets/styles/global-style.scss";
@import "./assets/styles/controls.scss";
@import "./assets/styles/media.scss";

.event-table-info {
  font-size: 1.25rem;
  line-height: 24px;
  list-style-type: disc;
  padding-inline-start: 10px;
}

.leaderboard-info {
  max-width: 628px;
  font-size: 1.25rem;
  line-height: 24.6px;
  margin-bottom: 6px;
}

app-home,
app-auth {
  position: relative;
  top: -50px;
  z-index: 1;
}